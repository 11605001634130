import React, { useEffect, useState } from 'react'; 
import SpinnerSVG from './SpinnerSVG';
import { useData } from '../config/DataContext';
import { useDesign } from './DesignContext';
import { useBalance } from './BalanceContext'; 

const GenerateDesgin =  ({UserIdQuery,PromptQuery})  => {
    const [data, setData] = useState(null);   
    const [isVisible, setIsVisible] = useState(false);
    const configData = useData();
    const {setDesignData} = useDesign();
    const {setBalanceData} = useBalance();
    let userName = localStorage.getItem('userName');
    useEffect(() => {  
      if (UserIdQuery && PromptQuery) {
      
        fetchData();
      }
    }, [UserIdQuery]);
    
    let token = localStorage.getItem('Authorization');
    const fetchData = () => {
     setIsVisible(true); 
         const response =   fetch(`${configData.apiUrl}Design/GenerateDesign?userId=${UserIdQuery}&prompt=${PromptQuery}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token 
                },
              })
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setDesignData(data);  
          setBalanceData(data);  
          setIsVisible(false);
         
        })
        .catch((error) => {
          console.log(error);
          setIsVisible(false);
        });
    };
 
    return (  
        <div  className='imagegenerated_main'> 
            {data ? (data.iterationImages.map((image, index) => (
              <div className='imagegenerated_main_sub'>
                  <div className='messageia'> 
                      <div className='ainame_rowplain'> 
                        <div className='messageuser_image' />  
                        <div className='ainame'> {userName} </div> 
                      </div>
                      <div className='row_plain'> 
                        <div className='table_contents'>  
                          <div className='title_table_contents'>  
                              {PromptQuery}
                          </div> 
                        </div>
                      </div>

         
                  </div> 
                  <div className='messageuser'> 
                    <div className='messageuser_row_plain'> 
                      <div className='messageuser_table_contents'> 
                        <div className='image_table_contents' />  
                          <div className='title_table_contents'>  
                          {image.revisedPrompt}
                          </div> 
                      </div>
                    </div> 
                  </div>

                  <div className='imagegenerated'>
                    <div className='imagegenerated_aiimage'> 
                      <div className='imagegenerated_frame'> 
                        <div className='imagegenerated_rectangle'>  
                                <img src={`data:image/${image.mediaTypeName};base64,${image.content}`} 
                                className='imagegenerated_imageai' alt={`${image.tag}`}   />    
                        </div>
                      </div> 
                    </div>
                    <div className='imagegenerated_createpost'>
                       Design saved in Creations. <a  href={configData.webUrl + 'MyStudio'}>Create a post</a>
                    </div>
                </div>
              </div>
                ))) : ( <span>  </span>) 
            }
            {isVisible && (
            <div className='messageuser'> 
                <div className='messageuser_row_plain'> 
                  <div className='messageuser_table_contents'> 
                      <div className='image_table_contents' />  
                        <div className='title_table_contents'> 
                          <SpinnerSVG /> 
                        </div>
                  </div>
                </div>
            </div> 
            )}
      </div>

    );  


  };
  
  export default GenerateDesgin;

  
   