import React, {useEffect} from 'react'; 
import { useNavigate } from 'react-router-dom';  
import '../styles/menu.css';

const Menu = ({ isPopupOpen, closePopup }) => {
  const navigate = useNavigate();  
  let userType = localStorage.getItem('userType'); 
  let userName = localStorage.getItem('userName');
  
  let userPhoto = localStorage.getItem('userPhoto');

  if (userPhoto) {
      try {
          userPhoto = JSON.parse(userPhoto);  
      } catch (error) {
          console.log('Error parsing user photo:', error);
          userPhoto = null; 
      }
  }
  

  const handleOverlayClick = (e) => {
   
    if (e.target === e.currentTarget) {
      closePopup();
    }
  };
   
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleLinkClick = (path) => {
    closePopup();
    
    if (isMobile && window.ReactNativeWebView) {
      if (path === "/SpotLight") {
        window.ReactNativeWebView.postMessage('openNativeSpotlight');
      }
      else {
       
        navigate(path);}
    } else {

      navigate(path);
    }
  };

  const logOut = () => {
    localStorage.clear();
    closePopup();
    navigate("/Login");
  };
  
  if (!isPopupOpen) return null;

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="content_menu">
       <div className="content_menu_sub"> 
          <div className="menu_header">    
            <div className="menu_section_header"> 
              <div className="menu_section_header_text">
                MENU
              </div>
            </div>
            <div className='menu_separator'/> 
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text"> 
                <button  className='item_button'>
                  <a href ="https://shop.getyouniq.com/collections/gallery"   >
                    Gallery
                  </a>
                </button>
          
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text"> 
                <button  className='item_button'>
                  <a href ="https://shop.getyouniq.com/pages/creators"   >
                    Creators
                  </a>
                </button>
               
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
                <button onClick={() => handleLinkClick("/SpotLight")} className='item_button'>Spotlight</button>
    
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
              <button onClick={() => handleLinkClick("/MyStudio")} className='item_button'>My Studio</button>
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
              <button onClick={() => handleLinkClick("/MyCreation")} className='item_button'>My Creations</button>
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
              <button onClick={() => handleLinkClick("/AIDesign")} className='item_button'>AI Design</button>
              </div>
            </div>
          </div>
          {userType === '2' && ( <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
              <button onClick={() => handleLinkClick("/CreationList")} className='item_button'>Creation List</button>
              </div>
            </div>
          </div>)}
       
        </div>

        <div className="userprofileItem">
          <div className="userprofileFoto">  
          {userPhoto && userPhoto.mediaTypeName && userPhoto.content ? ( <img src={`data:image/${userPhoto.mediaTypeName};base64,${userPhoto.content}`}
                         className='userprofileFoto' />)  : (<div className='icons_avatar'>
                            <svg className="svg-icon" width={50} viewBox="0 0 20 20">
                                <path d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z"></path>
                        </svg>
                        </div>  ) }
           </div>
            <div className="userprofileTitle">
              <div className="userprofileText">
                {userName}
              </div>
              <div className='logoutBlock' > <button onClick={() => logOut()} className='showAll_button   logoutBlock'>
              <svg fill="none" height="24" viewBox="0 0 24 24" width="24" 
              xmlns="http://www.w3.org/2000/svg"><path d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 
              18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8" 
              stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
                 Logout</button>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
