import React, { useEffect, useState, useCallback  } from 'react';
import { useData } from '../config/DataContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';

const SpotLightListImages = ({ UserIdQuery, PlatformQuery, PageNumberQuery,  FilterArrayCt, FilterArrayCl, FilterArrayDs, onDataFetchFiltersCt, onDataFetchFiltersCl, onDataFetchFiltersDs, SearchText }) => {
  const [dataAll, setDataAll] = useState([]);
  const [activeType, setActiveType] = useState('All');
  const [pageNumber, setPageNumber] = useState(PageNumberQuery || 1);
  const [checkedItems, setCheckedItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const configData = useData();
  const navigate = useNavigate();
  let token = localStorage.getItem('Authorization');

  const debouncedFetchData = useCallback(
    debounce((activeType, page, searchText) => {
      fetchData(activeType, page, searchText);
    },500),  
    [UserIdQuery, PlatformQuery, FilterArrayCt, FilterArrayCl, FilterArrayDs]  
  );
  
  useEffect(() => {
    if (UserIdQuery && PlatformQuery) {
      setPageNumber(1);
      setDataAll([]);
      debouncedFetchData(activeType, 1, SearchText);  
    }
  }, [UserIdQuery, FilterArrayCt, FilterArrayCl, FilterArrayDs, SearchText]);  
  

  const fetchData = async (ListSpotlightTypeQuery, page, searchText) => {
    try {
      const combinedFilters = [
        ...FilterArrayCt.map(item => `CategoryItems=${item}`),
        ...FilterArrayCl.map(item => `CategoryItems=${item}`),
        ...FilterArrayDs.map(item => `CategoryItems=${item}`)
      ];
     
      const categoryParams = combinedFilters.join('&');
      console.log(categoryParams);
    
      const response = await fetch(
        `${configData.apiUrl}Creation/ListSpotlights?userId=${UserIdQuery}&ListSpotlightType=${ListSpotlightTypeQuery}&platform=${PlatformQuery}&PageNumber=${page}&${categoryParams}&SearchText=${searchText}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token 
          },
        }
      );

      const data = await response.json();  

      //console.log("Data fetched:", data);
      const category = Array.from(new Set(data.filterableCategoryItems.filter(item => item.category==='Category'))); 
      onDataFetchFiltersCt(category); 
 
      const color = Array.from(new Set(data.filterableCategoryItems.filter(item => item.category==='Color'))); 
      onDataFetchFiltersCl(color); 

      const designedfor = Array.from(new Set(data.filterableCategoryItems.filter(item => item.category==='DesignedFor'))); 
      onDataFetchFiltersDs(designedfor); 

      
      if (Array.isArray(data.spotlights)) {
        if (searchText ==="" && FilterArrayCt ==="" && FilterArrayCl ==="" && FilterArrayDs ==="")
        {
            setDataAll(prevData => [...prevData, ...data.spotlights]);

        } else  setDataAll(data.spotlights);
       
        if (data.spotlights.length < 50) {
            setHasMore(false);
        }
      } else {
        console.log('Unexpected data format:', data.spotlights);
        setHasMore(false);
      }

    } catch (error) {
      console.log(error);
    }
  };


  const handleTypeChange = (type) => {
    setActiveType(type);  
    setPageNumber(1);  
    setDataAll([]);  

    setHasMore(true);  
    // fetchData(type, 1);   
    debouncedFetchData(type, 1, SearchText);  
  };
 
  const handleSpotlightClick = (linkUrl, status, shopifyLinkUrl ) => {  
   
    if (status === 'Spotlight')
    {
    
      navigate('/' + linkUrl);
    }
    else {
      if (shopifyLinkUrl) { 
        if (shopifyLinkUrl.startsWith('www.')) { 
          shopifyLinkUrl = `https://${shopifyLinkUrl}`;
        }
        window.open(shopifyLinkUrl);
      }
   } 
 
  }

  const handleCreatorClick = (linkUrl) => {   
      if (linkUrl) { 
        if (linkUrl.startsWith('www.')) { 
          linkUrl = `https://${linkUrl}`;
        }
        window.open(linkUrl);
      }  
  }

  const handleCheckboxChange = (id, isChecked) => {
    let checkedUpdate = [];
  
    if (checkedItems && checkedItems.includes(id) && isChecked === false) { 
      checkedUpdate = checkedItems.filter(Id => Id !== id);
    } else if (checkedItems && !checkedItems.includes(id) && isChecked === true) { 
      checkedUpdate = [...checkedItems, id];
    } else if (!checkedItems) { 
      checkedUpdate = [id];
    } else { 
      checkedUpdate = [...checkedItems];
    }
  
    setCheckedItems(checkedUpdate);
  
    const checkeddata = dataAll.map(spotlight => ({
      ...spotlight,
      checkStatus: checkedUpdate.includes(spotlight.id),
    }));
  
    setDataAll(checkeddata);   
  };
  
  const handleHideSubmit = async () => {  
    const ids = Array.from(checkedItems); 
    let requestBody = {
      process: 'Add',
      userId: UserIdQuery,  
      action: 'Hide',
      forType: "Spotlight",
      forIds: ids  
    };
  
    try {
      const response = await fetch(`${configData.apiUrl}Common/TakeAction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': token 
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response) { 
        setCheckedItems([]);   
        handleTypeChange(activeType);
      }
      console.log(JSON.stringify(requestBody));
    } 
    catch (error) {
      console.error('Error:', error); 
    }; 
  };
  

  const renderPublishedData = (data) => {
    if (checkedItems && checkedItems.length>0)
      {
          const checkeddata = data.map(spotlight => ({
          ...spotlight,
          checkStatus: checkedItems.includes(spotlight.id),
          }));
  
          data =checkeddata;
      } 
    const dataSpotlight = data || [];
    return dataSpotlight.map((spotlight) => (
      <div className='mycreationTable_box' key={spotlight.id}>
        {spotlight.creation.images && 
         (spotlight.creation.images.map((image) => {
          
            let path = '';
            if (image.url) {
              const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
              const startIndex = image.url.indexOf(baseUrl);
              if (startIndex !== -1) {
                path = configData.imageUrl + image.url.substring(baseUrl.length) + '/w=250';  
              }
            }  
            let imagenoUrl='https://imagedelivery.net/yJ5Gl_l36RVeosvhpEjUNw/'

            return (
            <div className='photos_favorite' key={image.id}> 
                <img
                src={path}
                alt={image.tag} 
                onClick={() => handleSpotlightClick(spotlight.linkUrl, spotlight.creation.status, spotlight.shopifyLinkUrl)} style={{ cursor : 'pointer'}}
                /> 


            {(activeType === 'Likes' || activeType === 'WishList') && (
              <div className='checkbox_icon_spotlight'>
                  <label className="checkbox_container">
                      <input
                        type="checkbox"
                        className='checkbox_custom'
                        id={`checkbox-${spotlight.id}`}
                        checked={spotlight.checkStatus}
                        onChange={(e) => handleCheckboxChange(spotlight.id, e.target.checked)}
                      />
                      <span className="checkbox_label"> </span>
                      <label htmlFor={`checkbox-${image.id}`}></label>
                  </label>
              </div>
            )}
            </div>
             );
        }))}
      
            
        <div className='mycreationTable_box_1'>
          <div className={activeType === 'Likes' || activeType === 'WishList' ? 'mycreationTable_box_rectangle_TextLike' : 'mycreationTable_box_rectangle_Text'}>
            <a onClick={() => handleSpotlightClick(spotlight.linkUrl, spotlight.creation.status, spotlight.shopifyLinkUrl)} >
              {spotlight.creation.title}
            </a>
          </div> 
          {activeType === 'Likes'  && (
          <div className='mycreationTable_box_rectangle_appIcon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
              <path d="M1.28679 1.28679C-0.428931 3.00252 -0.428931 5.78425 1.28679 7.49997L8.78682 15L16.2867 7.49997C18.0025 5.78425 18.0025 3.00251 16.2867 1.28679C14.571 -0.428931 11.7893 -0.428931 10.0736 1.28679L8.78682 2.57364L7.49997 1.28679C5.78425 -0.428931 3.00252 -0.428931 1.28679 1.28679Z" fill="#FEF6F6"/>
            </svg>
          </div>)}
          {activeType === 'WishList'  && (
          <div className='mycreationTable_box_rectangle_appIcon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
              <path d="M11.6665 1.66667C11.6665 0.746192 10.9203 0 9.99984 0H1.6665C0.746029 0 -0.000163078 0.746192 -0.000163078 1.66667V15L5.83317 12.0833L11.6665 15V1.66667Z" fill="white"/>
            </svg>
          </div>)}

        </div>
        <div className='mycreationTable_box_1'>
          <div className='mycreationTable_box_rectangle_Status'>
          <a onClick={() => handleCreatorClick(spotlight.creation.userProfile.linkUrl)} >
            {spotlight.creation.userProfile.name}
          </a>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <InfiniteScroll
    height={"100vh"}
    dataLength={dataAll.length}  
    next={() => setPageNumber(prevPage => {
      const nextPage = Number(prevPage) + 1;
      fetchData(activeType, Number(nextPage));
      return nextPage;
    })}
    hasMore={hasMore}
    
    endMessage={<p> </p>}
    className="infinite-scroll-container" >
      <div className='table_mycreation'>
        <div className='detailsTableLeft'>
          <div className='mycreationTable_header1'>
            <div  className='mycreationTable_header1Sub'>
              <div
                className='mycreationTable_LeftText'
                style={{ color: activeType === 'All' ? 'white' : 'grey' }}  
                >
                <a onClick={() => handleTypeChange('All')}>ALL DESIGNS</a>
              </div>
              <div
                className='mycreationTable_LeftText'
                style={{ color: activeType === 'Likes' ? 'white' : 'grey' }} 
                >
                <a onClick={() => handleTypeChange('Likes')}>LIKES</a>
              </div>
              <div
                className='mycreationTable_LeftText'
                style={{ color: activeType === 'WishList' ? 'white' : 'grey' }}  
                >
                <a onClick={() => handleTypeChange('WishList')}>WISHLIST</a>
              </div>
            </div>
            {(activeType === 'Likes' || activeType === 'WishList') && (
              <button className='showAll_button' onClick={handleHideSubmit}>
                
                <div className='button_hide'> 
                  <div className='mystudioback_label'>Hide</div>
                </div>
              </button>)
            }
          </div> 
  
            <div className='mycreationTable_boxes'>
              <div className='corner_rounding'>
                {renderPublishedData(dataAll)}
              </div>
            </div>
        
        </div>
      </div>    
    </InfiniteScroll>
  );
};

export default SpotLightListImages;
